import axios from "axios";
import './assets/main.css'
import "tailwindcss/tailwind.css"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'



const app = createApp(App)
app.use(router)
app.mount('#app')

console.log(import.meta.env.API_URL)


import { showToast } from "vant";
// 2. 引入组件样式
import 'vant/lib/index.css';

// 定义公共方法
app.config.globalProperties.$toast = (title, duration)=> {
    duration = duration || 2000;
    showToast({
        duration: duration,
        forbidClick: true, //禁止背景点击
        message: title || "加载中"
    })
};
console.log(import.meta.env.API_URL)
// 设置基础URL和请求头
const apiClient = axios.create({
    baseURL: '/b2c/', // 替换为你的 API 基础 URL
    timeout: 60000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer token', // 如果需要身份验证
    }
});
// 请求拦截器
apiClient.interceptors.request.use(
    (config) => {
        // 从 localStorage 或其他存储中获取 token
        config.headers['Token'] = localStorage.getItem('UserToken');

        return config; // 返回配置对象
    },
    (error) => {
        // 处理请求错误
        return Promise.reject(error);
    }
);

app.config.globalProperties.$get = async (url, param)=> {
    try {
        // app.config.globalProperties.$toast('123123')
        if (typeof param === 'string') url += '?' + param
        else if (param instanceof Object) url += '?' + Object.keys(param).map(v=> `${v}=${param[v]}`).join('&')

        const response = await apiClient.get(url);
        // console.log('GET 请求成功:', response.data);
        return response.data;
    } catch (error) {
        app.config.globalProperties.$toast('请求错误【GET】')
        // 根据需要处理错误
    }
};
app.config.globalProperties.$post = async (url, data)=> {
    try {
        // app.config.globalProperties.$toast('123123')
        const response = await apiClient.post(url, data || {});
        // console.log('GET 请求成功:', response.data);
        return response.data;
    } catch (error) {
        app.config.globalProperties.$toast('请求错误【POST】')
        // 根据需要处理错误
    }
};
app.config.globalProperties.$uploadFileToQiniu = (domainName, file)=> {
    return new Promise((resolve, reject) => {
        if (file) {
            app.config.globalProperties.$get('file/getUploadToken',{domainName: domainName || 'mobileTerminal', fileName: file.name}).then(res=> {
                if (res.code !== 0) {
                    return reject(res.message || '上传失败！')
                }
                let key = res.result.key
                let token = res.result.token
                let domain = res.result.domain
                let uploadUrl = "https://up-cn-east-2.qiniup.com/";
                let xhr = new XMLHttpRequest();
                xhr.open("POST", uploadUrl, true);
                // 构造 multipart/form-data 内容
                let data = new FormData();
                data.append('key', key);
                data.append('token', token);
                data.append('file', file);
                xhr.send(data);
                //监听状态
                xhr.onreadystatechange = ()=> {
                    if (xhr.readyState === 4) {
                        let key = JSON.parse(xhr.response || '{}')?.key
                        if (key) {
                            if (/\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(key)) key += "?imageslim"
                            resolve(domain + key)
                        } else {
                            reject('上传失败！')
                        }

                    }
                }
            }).catch(err => {
                reject('')
            })
        }
    })
}
app.config.globalProperties.$xxqyProductTypeMap = {
    "1": "卡密",
    "2": "直冲",
    "3": "代充",
    "4": "卡卷",
}
app.config.globalProperties.$isHome = location.pathname.includes('home')