import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'

console.log(location.href.includes('home'))
const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "",
            component: () => import("@/components/Tabs.vue"),
            redirect: "/",
            children: [
                ...[
                    location.pathname.includes('home') ? {
                        path: "/",
                        name: "Home",
                        component: () => import("@/views/Home.vue"),
                        meta: {
                            tabName: "首页",
                            token: false,
                            keepAlive: true
                        }
                    } : {
                        path: "/",
                        name: "Activity",
                        component: () => import("@/views/Activity.vue"),
                        meta: {
                            tabName: "首页",
                            token: false,
                            keepAlive: true
                        }
                    }
                ],
                {
                    path: "/shopCard",
                    name: "shopCard",
                    component: () => import("@/views/ShopCard.vue"),
                    meta: {
                        tabName: "购物车",
                        token: false,
                        keepAlive: true
                    }
                },
                {
                    path: "/mine",
                    name: "mine",
                    component: () => import("@/views/Mine.vue"),
                    meta: {
                        tabName: "我的",
                        token: false,
                        keepAlive: true
                    }
                },
                {
                    path: "/customerService",
                    name: "customerService",
                    component: () => import("@/views/CustomerService.vue"),
                    meta: {
                        tabName: "联系客服",
                        token: false,
                        keepAlive: true
                    }
                }
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import("@/views/login.vue")
        },
        {
            path: '/productList/:activityId?/:activityName?',
            name: 'productList',
            component: () => import("@/views/ProductList.vue")
        },
        {
            path: '/SearchTips/:activityId?/:activityName?',
            name: 'SearchTips',
            component: () => import("@/views/SearchTips.vue")
        },
        {
            path: '/GoodsDetail/:id/:activityId?/:activityName?',
            name: 'GoodsDetail',
            component: () => import("@/views/GoodsDetail.vue")
        },
        {
            path: '/OrderImmediately/:id/:activityId?/:activityName?/:ids?',
            name: 'OrderImmediately',
            component: () => import("@/views/OrderImmediately.vue")
        },
        {
            path: '/OrderManagement/:type?',
            name: 'OrderManagement',
            component: () => import("@/views/OrderManagement.vue")
        },
        {
            path: '/OrderDetail/:orderNo/:orderType',
            name: 'OrderDetail',
            component: () => import("@/views/OrderDetail.vue")
        },
        {
            path: '/xxqyProductList/:activityId?/:activityName?',
            name: 'xxqyProductList',
            component: () => import("@/views/xxqy/xxqyProductList.vue")
        },
        {
            path: '/xxqyProductDetail/:id/:activityId?/:activityName?',
            name: 'xxqyProductDetail',
            component: () => import("@/views/xxqy/xxqyProductDetail.vue")
        },
        {
            path: '/xxqyProductConfirmOrder/:id/:activityId?/:activityName?',
            name: 'xxqyProductConfirmOrder',
            component: () => import("@/views/xxqy/xxqyProductConfirmOrder.vue")
        },
        {
            path: '/xxqyPayOrder/:orderNo',
            name: 'xxqyPayOrder',
            component: () => import("@/views/xxqy/xxqyPayOrder.vue")
        },
        {
            path: '/xxqyOrderManagement',
            name: 'xxqyOrderManagement',
            component: () => import("@/views/xxqy/xxqyOrderManagement.vue")
        },
        {
            path: '/xxqyOrderDetail/:orderNo',
            name: 'xxqyOrderDetail',
            component: () => import("@/views/xxqy/xxqyOrderDetail.vue")
        },
        {
            path: '/AddressList',
            name: 'AddressList',
            component: () => import("@/views/AddressList.vue")
        },
        {
            path: '/Setting',
            name: 'Setting',
            component: () => import("@/views/Setting.vue")
        },
        {
            path: '/LogisticsInformation',
            name: 'LogisticsInformation',
            component: () => import("@/views/LogisticsInformation.vue")
        },
        {
            path: '/Myreceipt',
            name: 'Myreceipt',
            component: () => import("@/views/Myreceipt.vue")
        },
        {
            path: '/Wishlist',
            name: 'Wishlist',
            component: () => import("@/views/Wishlist.vue")
        },
        {
            path: '/Integraldetail',
            name: 'Integraldetail',
            component: () => import("@/views/Integraldetail.vue")
        },
        {
            path: '/Accountsecurity',
            name: 'Accountsecurity',
            component: () => import("@/views/Accountsecurity.vue")
        },
        {
            path: '/MemberList',
            name: 'MemberList',
            component: () => import("@/views/MemberList.vue")
        },
        {
            path: '/UpdatePayPassward',
            name: 'UpdatePayPassward',
            component: () => import("@/views/UpdatePayPassward.vue")
        },
        {
            path: '/BindMailbox',
            name: 'BindMailbox',
            component: () => import("@/views/BindMailbox.vue")
        },
        {
            path: '/Goodsjudgement/:supplierCode/:skuCode',
            name: 'Goodsjudgement',
            component: () => import("@/views/Goodsjudgement.vue")
        },
        {
            path: '/NoLoginProductList',
            name: 'NoLoginProductList',
            component: () => import("@/views/NoLoginProductList.vue"),
            meta: {
                tabName: "商品列表",
                token: false,
                keepAlive: true
            }
        },
        {
            path: '/CategoryList',
            name: 'CategoryList',
            component: () => import("@/views/CategoryList.vue"),
            meta: {
                tabName: "商品分类",
            }
        },
        {
            path: '/CategoryProductList/:code',
            name: 'CategoryProductList',
            component: () => import("@/views/CategoryProductList.vue"),
            meta: {
                tabName: "商品列表",
            }
        },
        {
            path: '/YouXuanShangJia',
            name: 'YouXuanShangJia',
            component: () => import("@/views/YouXuanShangJia.vue"),
            meta: {
                tabName: "优选商家",
            }
        },
        {
            path: '/DianPuShow/:supplierCode',
            name: 'DianPuShow',
            component: () => import("@/views/DianPuShow.vue"),
            meta: {
                tabName: "店铺首页",
            }
        },
        {
            path: '/DianPuZhu',
            name: 'DianPuZhu',
            component: () => import("@/views/DianPuZhu.vue"),
            meta: {
                tabName: "店铺关注",
            }
        }
    ]
})

export default router
