<script setup>
</script>

<template>
  <header></header>
  <router-view></router-view>
  <!-- 开启顶部安全区适配 -->
<!--  <van-nav-bar safe-area-inset-top />-->

  <!-- 开启底部安全区适配 -->
<!--  <van-number-keyboard safe-area-inset-bottom />-->
</template>

<style scoped>

</style>
